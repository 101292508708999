@import "setting";

.sub-eyecatch{
	&:before{
		@include mq-down(sm){
			padding-top: 40% !important;
		}
	}
	&__img{
		background: url(../img/single/sub-eyecatch.png) center center no-repeat;
	}
}

.single-post{
	padding-bottom: 120px;

	&__module{
		padding-top: 80px;

		@include mq-down(xs){
			padding-top: 30px;
		}

		&__title{
			@include font-size(24);
			line-height: font-height(30,24);
		}

		&__date{
			display: inline-block;
			margin-right: 10px;
		}

		&__category{
			display: inline-block;
			background: #3d3d3d;
			padding: 1px 10px;
		}

		&__entry{
			@include font-size(16);
			line-height: font-height(24,16);
			padding: 30px 0 120px;
			border-top: solid 2px #807d7d;
			border-bottom: solid 2px #807d7d;
			margin-bottom: 60px;
		}

		&__sidebar{
			margin-bottom:60px;

			h3{
				font-family: $font-en;
				@include font-size(35);
				line-height: font-height(32,35);
				letter-spacing: 2px;
				background: #807d7d;
				padding: 5px 10px;
			}

			&__category{
				padding-left: 0;
				list-style: none;

				li{
					&:before{
						content: ">";
						margin-right: 15px;
					}

					@include font-size(18);
					line-height: font-height(46,18);

				}
			}

			&__recentposts{
				padding-left: 0;
				list-style: none;

				.new-posts{
					border-bottom: solid 1px #fff;
					padding: 10px 0;

					&__date{
						float: left;
						margin-right: 10px;
					}

					&__cat{
						background: #3d3d3d;
						padding: 1px 10px;
					}
				}

			}
		}


	}

	.navigation{
		a{
			display: block;
			border:solid 1px #fff;
			text-align: center;
			padding: 10px;
		}
	}
}

.category-archive{
	padding: 80px 0;

	@include mq-down(xs){
		padding: 30px 0;
	}

	.single-archive-title{
		float: left;
	}

	.single-archive-day{
		float: right;
		margin: 20px 10px;
	}

	.excerpt{
		border-top: solid 5px #807d7d;
		padding: 10px 0 30px;
		p{
			margin: 0 0 20px;
		}
	}

	.more-link {
		border: solid 1px #807d7d;
		padding: 5px 10px;
		background: #807d7d;
		margin: 20px 0;
	}
}

.notfound{
	margin: 60px auto 0;

	@include mq-down(xs){
		margin: 60px auto;
	}

	.search{
		margin: 60px 0 20px;
		padding-bottom: 10px;
		border-bottom: solid 1px #fff;

		li{
			a{
				text-decoration: underline;
			}
		}
	}

}
