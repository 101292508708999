//BEM
//.block{}
//.block__element{} エレメント
//.block--modifier{} 変化
//.blck-item__elemnt {} 単語区切り
@charset "utf-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: 'BebasNeue';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/bebasneue-regular.otf) format('opentype'),
  url(../fonts/bebasneue-regular.woff) format('woff');
}

//フォント
$font-noto:'Noto Sans Japanese', serif;
$font-en:'BebasNeue';

//カラー
$black:#000;
$white:#fff;

// ブレイクポイント
// min-width
$breakpoint-up: (
  'sp': 'screen and (min-width: 420px)',
  'xs': 'screen and (min-width: 768px)',
  'sm': 'screen and (min-width: 992px)',
  'md': 'screen and (min-width: 1200px)',
  'lg': 'screen and (min-width: 1600px)',
) !default;

// max-width
$breakpoint-down: (
  'sp': 'screen and (max-width: 420px)',
  'xs': 'screen and (max-width: 767px)',
  'sm': 'screen and (max-width: 991px)',
  'md': 'screen and (max-width: 1199px)',
  'lg': 'screen and (max-width: 1599px)',
) !default;

//@mixinの定義
@mixin mq-up($breakpoint: xs) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: xs) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin font-size($base: 16){
  font-size: $base + px;
  font-size: ($base / 10) + rem;
}

@mixin opacity ($aOpacityNum) {
    opacity: #{$aOpacityNum};
    filter: alpha(opacity=($aOpacityNum * 100));
}

@mixin poslt ($left:0,$top:0){
    position: absolute;
    left: #{$left}px;
    top: #{$top}px;
}

@mixin posrt ($right:0,$top:0){
    position: absolute;
    right: #{$right}px;
    top: #{$top}px;
}

@mixin poslb ($left:0,$bottom:0){
    position: absolute;
    left: #{$left}px;
    bottom: #{$bottom}px;
}

@mixin posrb ($right:0,$bottom:0){
    position: absolute;
    right: #{$right}px;
    bottom: #{$bottom}px;
}

//関数定義
@function half($value){
  @return round($value / 2);
}

@function font-height($line-height,$base) {
    @return ($line-height / $base);
}

//extend用
.lead-tc{
  text-align: center;

  @include mq-down(xs) {
    text-align: justify;
  }
}

.block-center{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
